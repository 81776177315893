/**
 * DO NOT MANUALLY EDIT THIS FILE
 * This file is generated automatically by the build script on prebuild step.
 * Please refer to documentation for more information
 */

export default function Settings({ children }) {
  return children({
    mainNavigation: [
      {
        label: 'Paintings',
        href: '/art/paintings',
        columns: [
          {
            items: [
              {
                label: 'Blue Chip Paintings',
                href: '/art/paintings?stage=established',
                obfuscate: true,
                icon: 'established',
                bold: true,
                className: '',
              },
              {
                label: 'Emerging Art',
                href: '/art/paintings?stage=emerging',
                obfuscate: true,
                icon: 'emerging',
                bold: true,
                className: '',
              },
              {
                label: 'New and Notable',
                href: '/art/paintings?stage=new',
                obfuscate: true,
                icon: 'new',
                bold: true,
                className: '',
              },
              {
                label: 'All Paintings',
                href: '/art/paintings',
                bold: true,
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              { type: 'separator', className: '' },
              {
                label: 'All Paintings',
                href: '/art/paintings',
                obfuscate: true,
                bold: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            items: [
              { label: 'Abstract Paintings', href: '/art/paintings/abstract', className: '' },
              { label: 'Figurative Paintings', href: '/art/paintings/figurative', className: '' },
              { label: 'Oil Paintings', href: '/art/paintings/oil', className: '' },
              { label: 'Acrylic Paintings', href: '/art/paintings/acrylic', className: '' },
              { label: 'Landscape Paintings', href: '/art/paintings/landscapes', className: '' },
              { label: 'Art Deco Paintings', href: '/art/paintings/art-deco', className: '' },
              { label: 'Nude Paintings', href: '/art/paintings/nudes', className: '' },
            ],
            className: '',
          },
          {
            items: [
              {
                type: 'box',
                label: 'Best In Painting',
                text: 'Unmissable paintings from artists on the rise',
                href: '/collection/1421/the-best-in-paintings-this-year',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/05/12c87adf-15e0-4b5a-90b3-0ac4db8a8fa9.jpg',
                className: '',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
      {
        label: 'Prints',
        href: '/art/prints',
        columns: [
          {
            items: [
              {
                label: 'Blue Chip Prints',
                href: '/art/prints?stage=established',
                obfuscate: true,
                icon: 'established',
                bold: true,
                className: '',
              },
              {
                label: 'Emerging Art',
                href: '/art/prints?stage=emerging',
                obfuscate: true,
                icon: 'emerging',
                bold: true,
                className: '',
              },
              {
                label: 'New and Notable',
                href: '/art/prints?stage=new',
                obfuscate: true,
                icon: 'new',
                bold: true,
                className: '',
              },
              {
                label: 'All Prints',
                href: '/art/prints',
                bold: true,
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              { type: 'separator', className: '' },
              {
                label: 'All Prints',
                href: '/art/prints',
                obfuscate: true,
                bold: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            items: [
              { label: 'Street Art Prints', href: '/art/prints/street-art', className: '' },
              { label: 'Pop Art Prints', href: '/art/prints/pop-art', className: '' },
              { label: 'Landscape Prints', href: '/art/prints/landscapes', className: '' },
              { label: 'Botanical Prints', href: '/art/prints/botanical', className: '' },
              { label: 'Animal Prints', href: '/art/prints/animals', className: '' },
              { label: 'Screenprint Prints', href: '/art/prints/screenprint', className: '' },
              { label: 'Lino Prints', href: '/art/prints/lino', className: '' },
              { label: 'Mono Prints', href: '/art/prints/mono', className: '' },
            ],
            className: '',
          },
          {
            items: [
              {
                type: 'box',
                label: 'Best In Print',
                text: 'Premium prints for discerning collectors',
                href: '/collection/1427/best-in-prints',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/05/bcb78719-4f1a-4884-b54f-3d085457b599.jpg',
                className: '',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
      {
        label: 'Photography',
        href: '/art/photography',
        columns: [
          {
            items: [
              {
                label: 'Blue Chip Photography',
                href: '/art/photography?stage=established',
                obfuscate: true,
                icon: 'established',
                bold: true,
                className: '',
              },
              {
                label: 'Emerging Art',
                href: '/art/photography?stage=emerging',
                obfuscate: true,
                icon: 'emerging',
                bold: true,
                className: '',
              },
              {
                label: 'New and Notable',
                href: '/art/photography?stage=new',
                obfuscate: true,
                icon: 'new',
                bold: true,
                className: '',
              },
              {
                label: 'All Photography',
                href: '/art/photography',
                bold: true,
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              { type: 'separator', className: '' },
              {
                label: 'All Photography',
                href: '/art/photography',
                obfuscate: true,
                bold: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            items: [
              { label: 'Abstract Photography', href: '/art/photography/abstract', className: '' },
              {
                label: 'Landscape Photography',
                href: '/art/photography/landscapes',
                className: '',
              },
              { label: 'Nude Photography', href: '/art/photography/nudes', className: '' },
              {
                label: 'Black and White Photography',
                href: '/art/photography/black-white',
                className: '',
              },
              { label: 'Digital Photography', href: '/art/photography/digital', className: '' },
            ],
            className: '',
          },
          {
            items: [
              {
                type: 'box',
                label: 'Best In Photography',
                text: 'Lens-crafted excellence for all tastes',
                href: '/collection/1151/inspired-by-world-photography-day',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/05/6b625cfc-11aa-4b6d-a79c-4dce0fc547ce.jpg',
                className: '',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
      {
        label: 'Sculpture',
        href: '/art/sculpture',
        columns: [
          {
            items: [
              {
                label: 'Blue Chip Sculpture',
                href: '/art/sculpture?stage=established',
                obfuscate: true,
                icon: 'established',
                bold: true,
                className: '',
              },
              {
                label: 'Emerging',
                href: '/art/sculpture?stage=emerging',
                obfuscate: true,
                icon: 'emerging',
                bold: true,
                className: '',
              },
              {
                label: 'New and Notable',
                href: '/art/sculpture?stage=new',
                obfuscate: true,
                icon: 'new',
                bold: true,
                className: '',
              },
              {
                label: 'All Sculpture',
                href: '/art/sculpture',
                bold: true,
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              { type: 'separator', className: '' },
              {
                label: 'All Sculpture',
                href: '/art/sculpture',
                obfuscate: true,
                bold: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            items: [
              { label: 'Abstract Sculpture', href: '/art/sculpture/abstract', className: '' },
              { label: 'Figurative Sculpture', href: '/art/sculpture/figurative', className: '' },
              { label: 'Nude Sculpture', href: '/art/sculpture/nudes', className: '' },
              { label: 'Metal Sculpture', href: '/art/sculpture/metal', className: '' },
              { label: 'Bronze Sculpture', href: '/art/sculpture/bronze', className: '' },
              { label: 'Wood Sculpture', href: '/art/sculpture/wood', className: '' },
              { label: 'Ceramic Sculpture', href: '/art/sculpture/ceramic', className: '' },
            ],
            className: '',
          },
          {
            items: [
              {
                type: 'box',
                label: 'Best In Sculpture',
                text: 'Superlative sculptures to suit any space',
                href: '/collection/1429/best-in-sculpture',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/05/61e3570b-7264-4625-a3cc-37a2ebcc5bda.jpg',
                className: '',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
      {
        label: 'Drawings',
        href: '/art/drawings',
        columns: [
          {
            items: [
              {
                label: 'Blue Chip Drawings',
                href: '/art/drawings?stage=established',
                obfuscate: true,
                icon: 'established',
                bold: true,
                className: '',
              },
              {
                label: 'Emerging',
                href: '/art/drawings?stage=emerging',
                obfuscate: true,
                icon: 'emerging',
                bold: true,
                className: '',
              },
              {
                label: 'New and Notable',
                href: '/art/drawings?stage=new',
                obfuscate: true,
                icon: 'new',
                bold: true,
                className: '',
              },
              {
                label: 'All Drawings',
                href: '/art/drawings',
                bold: true,
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              { type: 'separator', className: '' },
              {
                label: 'All Drawings',
                href: '/art/drawings',
                obfuscate: true,
                bold: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            items: [
              { label: 'Realistic Drawings', href: '/art/drawings/realistic', className: '' },
              { label: 'Abstract Drawings', href: '/art/drawings/abstract', className: '' },
              { label: 'Illustrative Drawings', href: '/art/drawings/illustrative', className: '' },
              { label: 'Nude Drawings', href: '/art/drawings/nudes', className: '' },
              { label: 'Portrait Drawings', href: '/art/drawings/portraits', className: '' },
              { label: 'Pencil Drawings', href: '/art/drawings/pencil', className: '' },
              { label: 'Charcoal Drawings', href: '/art/drawings/charcoal', className: '' },
              { label: 'Ink Drawings', href: '/art/drawings/ink', className: '' },
            ],
            className: '',
          },
          {
            items: [
              {
                type: 'box',
                label: 'Best In Drawing',
                text: 'Gallery-grade drawings by unrivalled talent',
                href: '/collection/1428/best-in-drawing',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/05/bfb47e77-ea1a-4f65-904f-953b23701df5.jpg',
                className: '',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
      {
        label: 'Artists',
        href: '/artists',
        columns: [
          {
            items: [
              {
                label: 'All Artists',
                href: '/artists',
                bold: true,
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              { label: 'Painters', href: '/artists/painters', bold: true, className: '' },
              { label: 'Printmakers', href: '/artists/printmakers', bold: true, className: '' },
              { label: 'Photographers', href: '/artists/photographers', bold: true, className: '' },
              { label: 'Sculptors', href: '/artists/sculptors', bold: true, className: '' },
              { label: 'Draftspeople', href: '/artists/draftspersons', bold: true, className: '' },
              { label: 'Collagists', href: '/artists/collagists', bold: true, className: '' },
              { type: 'separator', className: '' },
              {
                label: 'All Artists',
                href: '/artists',
                obfuscate: true,
                bold: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Curatorial Process',
                href: 'https://get.riseart.com/curation/',
                obfuscate: true,
                external: true,
                bold: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            label: 'Career Stage',
            items: [
              {
                label: 'Established Artists',
                href: '/artists?stage=established',
                obfuscate: true,
                icon: 'established',
                bold: true,
                className: '',
              },
              {
                label: 'Emerging Artists',
                href: '/artists?stage=emerging',
                obfuscate: true,
                icon: 'emerging',
                bold: true,
                className: '',
              },
              {
                label: 'New & Noteworthy Artists',
                href: '/artists?stage=new',
                obfuscate: true,
                icon: 'new',
                bold: true,
                className: '',
              },
              { type: 'separator', className: '' },
              {
                label: 'Artists to Watch',
                href: '/collection/1415/artists-to-watch',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Timeless Masters',
                href: '/collection/1672/works-by-timeless-masters',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            label: 'Featured Artists',
            href: '/artists',
            obfuscate: true,
            items: [
              {
                label: 'Andy Warhol',
                href: '/artist/16350/andy-warhol',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/05/22d5e9f4-3712-458a-8ba9-9a702472c831.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Sandra Blow',
                href: '/artist/98512/sandra-blow',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/77e3fc56-dff8-41d0-a561-7374e19f8b7d.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Tabitha Soren',
                href: '/artist/115856/tabitha-soren',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/05/d8241dbb-f35f-4d7a-ade5-07474c0a2c95.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Patrick Hughes',
                href: '/artist/99286/patrick-hughes',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/0357cd5d-61b5-463f-a1ba-7d3c1fce1a21.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Day Bowman',
                href: '/artist/64695/daybowman',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/77ccf544-a076-41ef-b2dd-42e9a5c393fb.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Nelson Makamo',
                href: '/artist/63379/nelson-makamo',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/603c9976-656b-49c6-a6a4-bfcbdf7f89dc.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            label: '',
            items: [
              {
                label: 'Takashi Murakami',
                href: '/artist/8932/takashi-murakami',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/05/94d2d6b3-bc92-4a04-b257-10b1a95c61ba.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Daisy Cook',
                href: '/artist/28944/daisy-cook',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/db149618-ba1b-450b-a000-dc4e24ae8789.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Fred Ingrams',
                href: '/artist/58960/fred-ingrams',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/05151fd7-0d0d-4586-849c-9694feaad7b2.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Barbara Rae',
                href: '/artist/17409/barbara-rae',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/0153be46-b224-430b-b1bd-1806ccd0668f.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Bruce Mclean',
                href: '/artist/16003/bruce-mclean',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/8d246e75-28f8-4234-88c3-7766239a147a.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Tracey Emin',
                href: '/artist/24975/tracey-emin',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/05/05e5c557-a706-4f76-be69-b2ea6719059d.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
      {
        label: 'Inspiration',
        href: '/articles',
        columns: [
          {
            label: 'Editorial',
            href: '/articles',
            obfuscate: true,
            items: [
              {
                label: 'Editorial',
                href: '/articles',
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              { label: 'Artist Interviews', href: '/articles/artist-interviews', className: '' },
              { label: 'Art News', href: '/articles/art-news', className: '' },
              { label: 'Upcoming Events', href: '/events', className: '' },
              { type: 'separator', className: '' },
              {
                label: 'Art Catalogue',
                href: 'https://get.riseart.com/catalogues/',
                obfuscate: true,
                external: true,
                className: '',
              },
              { label: 'Art Guides', href: '/guides', className: '' },
              { label: 'News Feed', href: '/activity', className: '' },
            ],
            className: '',
          },
          {
            label: 'Collections',
            href: '/collections',
            items: [
              {
                label: 'Art Collections',
                href: '/collections',
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              {
                label: "Curator's Picks",
                href: '/collection/1313/curators_picks',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Timeless Masters',
                href: '/collection/1672/works-by-timeless-masters',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'New In This Week',
                href: '/collection/1402/new-in-this-week',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: "Collector's Wishlist",
                href: '/collection/222/collectors-have-their-eye-on',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              { type: 'separator', className: '' },
              {
                label: 'Artists On The Rise',
                href: '/art?stage=emerging',
                obfuscate: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Large Format Artworks',
                href: '/art?size=L-XXL',
                obfuscate: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Affordable Art Picks',
                href: '/art?price=0-1500',
                obfuscate: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            label: 'Services',
            items: [
              {
                label: 'Art Personality Test',
                href: '/quiz/start',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Personal Art Advisory',
                href: '/talk-to-curator',
                obfuscate: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Commission An Artwork',
                href: 'https://get.riseart.com/commissions/',
                obfuscate: true,
                external: true,
                className: '',
              },
              {
                label: 'Make An Offer',
                href: '/article/2731/make-an-offer',
                obfuscate: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              { type: 'separator', className: '' },
              {
                label: 'Gift Card',
                href: '/gift',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            items: [
              {
                type: 'box',
                label: 'Catalogue',
                text: 'Original art from artists on the rise',
                href: 'https://get.riseart.com/catalogues/',
                obfuscate: true,
                external: true,
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/01/3c3e9a64-e502-42cf-8036-562527a6548d.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
      {
        label: 'Art Advisory',
        href: '/talk-to-curator',
        obfuscate: true,
        columns: [
          {
            label: 'Art Advisory',
            href: '/talk-to-curator',
            obfuscate: true,
            items: [
              {
                label: 'Curation',
                href: 'https://get.riseart.com/curation/',
                obfuscate: true,
                external: true,
                className: '',
              },
              { label: 'Art Advisory Service', href: '/talk-to-curator', className: '' },
              { label: 'Art Rentals', href: '/about/rentals', className: '' },
              {
                label: 'Free and Easy Returns',
                href: 'https://get.riseart.com/returns/',
                obfuscate: true,
                external: true,
                className: '',
              },
              { type: 'separator', className: '' },
              {
                label: 'Contact an Art Advisor',
                href: '/talk-to-curator#enquire-form',
                obfuscate: true,
                external: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            label: 'Workplace & Trade Advisory',
            items: [
              { label: 'Workplace Solutions', href: '/art-for-the-office', className: '' },
              {
                label: 'Designers & Hospitality',
                href: '/art-for-interior-designers',
                className: '',
              },
              { type: 'separator', className: '' },
              {
                label: 'Contact an Art Consultant',
                href: 'https://riseart.typeform.com/to/XsVlGfGL',
                obfuscate: true,
                external: true,
                className: '',
              },
            ],
            className: '',
          },
          {
            items: [
              {
                type: 'box',
                label: 'Soho Home x Rise Art',
                text: 'Recreate the feel of Soho Home studios with our curated collections',
                href: '/sohohome',
                obfuscate: true,
                external: true,
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/03/ad4976a7-eaed-4a08-bdad-6042ceddd4b9.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
    ],
    footerLinks: [
      {
        name: 'FOR ARTISTS',
        links: [
          { label: 'Submit Your Art', href: '/art/submit', obfuscate: true, external: true },
          {
            label: 'Seller Console',
            href: 'https://sellers.riseart.com',
            obfuscate: true,
            external: true,
          },
        ],
      },
      {
        name: 'FOR COLLECTORS',
        links: [
          {
            label: 'Customer reviews',
            href: 'https://www.reviews.io/company-reviews/store/riseart-com',
            obfuscate: true,
            external: true,
          },
          {
            label: 'How we curate',
            href: 'https://get.riseart.com/curation/',
            obfuscate: true,
            external: true,
          },
          {
            label: 'Returns',
            href: 'https://get.riseart.com/returns/',
            obfuscate: true,
            external: true,
          },
          {
            label: 'Framing your artwork',
            href: 'https://get.riseart.com/framing/',
            obfuscate: true,
            external: true,
          },
          {
            label: "Customer's FAQ",
            href: 'https://help.riseart.com/',
            obfuscate: true,
            external: true,
          },
        ],
      },
      {
        name: 'ABOUT US',
        links: [
          { label: 'About Us', href: '/about-us', obfuscate: true, external: false },
          { label: 'Contact Us', href: '/contact', obfuscate: true, external: false },
          { label: 'Gift Cards', href: '/gift', obfuscate: true, external: false },
          { label: 'Legal', href: '/legal', obfuscate: true, external: false },
        ],
      },
    ],
  });
}
